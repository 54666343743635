import * as React from "react"
import styled from "styled-components"
import Accordion from "../../components/accordion/Accordion"
import AccordionItem from "../../components/accordion/AccordionItem"
import AccordionHeader from "../../components/accordion/AccordionHeader"
import AccordionBody from "../../components/accordion/AccordionBody"
import Subheading from "../../components/landing-page/Subheading"
import { skyBlue, lightBlack } from "../../constants/colors"

const Container = styled.div`
  margin: 0 auto;
  max-width: 880px;
`

const SubheadingContainer = styled.div`
  margin-bottom: 50px;
`

const faqs = [
  {
    title: "I know the address, can I just send a gift?",
    content: (
      <>
        Not currently, although this is something we will look to add in the future. For now you
        would need to place an order as usual through{" "}
        <a target="_blank" href={"https://www.socialsupermarket.org"}>
          the website
        </a>
        . Or for large orders get in touch at info@socialsupermarket.org.
      </>
    ),
  },
  {
    title: "I don’t want to share my team’s email addresses, can I still use the Platform?",
    content: `No problem, on the order page you can enable a "Public Selection Page" which is a 
                unique link you can share internally, bypassing the need to provide any email 
                addresses.`,
  },
  {
    title: "Is the claim link unique to each recipient?",
    content: `When inviting recipients via email, each recipient will be provided with a unique
                link. This means only they can submit their response, and only once.`,
  },
  {
    title: "What if someone who’s not in my team submits a choice?",
    content: `You approve all responses before payment. So, if for any reason your gift selection
                link is shared with someone that isn’t on your gift list and they submit a choice,
                you can simply remove it.`,
  },
  {
    title: "Are all Social Supermarket products on the Platform?",
    content: `Not all products that you find on Social Supermarket are included in the Platform.
                The Platform contains a curated range of our most giftable products from our most
                trusted and popular brands. If you’ve spotted a product that you would like to 
                send that isn’t available in the Platform, please get in touch and we will see what
                we can do.`,
  },
  {
    title: "I’m registered but I’m not sure how to get started.",
    content: (
      <>
        The best way for us to tell you about all the great features you can use now you’re
        registered is to{" "}
        <a
          href="https://meetings.hubspot.com/barney-close?uuid=415ede49-cbc5-4e27-81e7-eef4f3ddd62c"
          target="_blank"
        >
          book a demo
        </a>{" "}
        with us. Alternatively, check out our{" "}
        <a href="https://app.socialsupermarket.org/guide" target="_blank">
          user guide
        </a>
        .
      </>
    ),
  },
]

interface Props {}

const TeamGiftingFaqAccordion: React.FC<Props> = () => {
  return (
    <Container>
      <SubheadingContainer>
        <Subheading color={lightBlack} isBordered borderColor={skyBlue}>
          Frequently asked questions
        </Subheading>
      </SubheadingContainer>
      <Accordion>
        {faqs.map(({ title, content }) => (
          <AccordionItem>
            <AccordionHeader>{title}</AccordionHeader>
            <AccordionBody>{content}</AccordionBody>
          </AccordionItem>
        ))}
      </Accordion>
    </Container>
  )
}

export default TeamGiftingFaqAccordion
