import * as React from "react"
import styled from "styled-components"
import { FC } from "react"
import { minionFont } from "../../../../../constants/fonts"
import { desktop } from "../../../../../constants/screenSizes"

const Container = styled.div`
  font-family: ${minionFont};
  font-size: 32px;
  margin-left: 65px;
  margin-bottom: 70px;

  @media (max-width: ${desktop}px) {
    margin-left: 20px;
    margin-bottom: 50px;
  }
`

interface Props {
  children: React.ReactNode
}

const LpIndentedTitle: FC<Props> = ({ children }) => {
  return <Container>{children}</Container>
}

export default LpIndentedTitle
