import * as React from "react"
import styled from "styled-components"
import { FC } from "react"
import { largeDesktop, tablet } from "../../../../../constants/screenSizes"

const Container = styled.div<{ backgroundColor }>`
  padding: 70px 0;
  background-color: ${({ backgroundColor }) => backgroundColor};

  @media (max-width: ${largeDesktop}px) {
    padding: 70px 10px;
  }

  @media (max-width: ${tablet}px) {
    padding: 50px 10px;
  }
`

interface Props {
  children?: React.ReactNode
  backgroundColor?: string
  className?: string
}

const LPBlock: FC<Props> = ({ className, children, backgroundColor = "white" }) => {
  return (
    <Container backgroundColor={backgroundColor} className={className}>
      {children}
    </Container>
  )
}

export default LPBlock
