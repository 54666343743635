import * as React from "react"
import styled from "styled-components"
import { FC } from "react"

const Container = styled.div`
  max-width: 1200px;
  margin: auto;
`

interface Props {
  children: React.ReactNode
}

const LPBody: FC<Props> = ({ children }) => {
  return <Container>{children}</Container>
}

export default LPBody
