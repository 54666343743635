import * as React from "react"
import { FC } from "react"
import CorporateQuotes from "../../../../page/corporate/corporate-quotes/CorporateQuotes"
import LPBlock from "../layout/LPBlock"
import LPBody from "../layout/LPBody"
import LpIndentedTitle from "../layout/LPIndentedTitle"
import styled from "styled-components"

const StyledBlock = styled(LPBlock)`
  padding-bottom: 0;
`

interface Props {}

const LPCorporateQuotesBlock: FC<Props> = () => {
  return (
    <StyledBlock>
      <LPBody>
        <LpIndentedTitle>
          Here's what <b>our customers</b> have to say
        </LpIndentedTitle>
        <CorporateQuotes />
      </LPBody>
    </StyledBlock>
  )
}

export default LPCorporateQuotesBlock
